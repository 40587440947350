import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import WhiteDiv from '../../components/WhiteDiv/WhiteDiv'

const AccountRecoveryService = require('../../services/AccountRecoveryService')

const ResetPassword = () => {
  // const navigate = useNavigate();

  const { register, formState: { errors }, handleSubmit, watch } = useForm({})
  const password = useRef({})
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  password.current = watch('password', '')
  const onSubmit = async (data) => {
    AccountRecoveryService.ResetPassword(data, urlParams.get('t'))
  }

  return (
    <>
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl'>
          <div className='max-w-md mx-auto space-y-6 my-4'>
            <h2 className='items-center font-semibold text-lg'>
              Escribe tu nueva contraseña
            </h2>
            <span>
              Debe tener al menos 8 caracteres e incluir 1 letra, 1 número y 1
              carácter especial.
            </span>
            <form className='space-y-6' onSubmit={(e) => e.preventDefault()}>
              <input
                name='password'
                type='password'
                className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 sm:text-sm'
                placeholder='Nueva contraseña'
                {...register('password', {
                  required: 'You must specify a password',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters'
                  }
                })}
              />
              {errors.password && (
                <span className='text-red-500 text-sm' role='alert'>
                  Este campo obligatorio.
                </span>
              )}

              <input
                name='password_repeat'
                type='password'
                className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 sm:text-sm'
                placeholder='Repite tu nueva contraseña'
                {...register('password_repeat', {
                  validate: (value) =>
                    value === password.current || 'The passwords do not match'
                })}
              />
              {errors.password_repeat && (
                <span className='text-red-500 text-sm' role='alert'>
                  Las contraseñas no coinciden.
                </span>
              )}

              <div className='flex flex-row-reverse'>
                <button
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
                  className='rounded-md shadow-s bg-indigo-500 text-white font-semibold py-3 px-5 mr-3'
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default ResetPassword
