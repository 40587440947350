import axios from 'axios'
import { dbapiEndpoint, config } from '../utils/httpVars'

let refreshing = false

export const refreshSession = async (refreshToken) => {
  try {
    if (refreshing) {
      // Si ya se está refrescando, no se llama a la función de nuevo
      return
    }

    refreshing = true

    const response = await axios.post(`${dbapiEndpoint}/api/v1/auth/refresh`, { refreshToken }, config)

    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)

    refreshing = false

    return response.data.accessToken
  } catch (error) {
    refreshing = false
    throw new Error('Error al refrescar el access token')
  }
}
