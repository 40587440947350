import React from 'react'
// const businessService = require('../../services/businessService')

// async function handleClick () {
//   const data = await businessService.orderReady({
//     status: 'ready'
//   })
// }

const CheckButton = ({ props, onClick }) => (
  <>
    {/* <button className='inline-flex items-center px-4 py-2 bg-green-500 hover:bg-green-600 text-white text-sm font-medium rounded-2xl' onClick={onClick}>
      <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5 13l4 4L19 7' />
      </svg>
      <span className='text-white text-sm'>{props.title}</span>
    </button> */}

    <button className='inline-flex items-center text-green-500 hover:text-green-800' onClick={onClick}>
      <span className='text-4xl text-inherit'>
        ✔
      </span>
      <span className='text-inherit'>{props.title}</span>
    </button>
  </>
)

export default CheckButton
