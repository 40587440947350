import { Route, Routes, Navigate } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import RouteWithoutUserSession from './components/RouteWithoutUserSession/RouteWithoutUserSession'

import './App.css'
import Login from './pages/AuthPages/Login.js'
import Register from './pages/AuthPages/Register.js'
import ForgotPassword from './pages/AuthPages/ForgotPassword'
import ResetPassword from './pages/AuthPages/ResetPassword'

import Layout from './pages/Layout'
import TableManagement from './pages/ProfilePages/TableManagement'
// import EditMyAccount from './pages/ProfilePages/ConfigurationPages/EditMyAccount'
// import EditProfilePhoto from './pages/ProfilePages/ConfigurationPages/EditProfilePhoto'
import MyConfiguration from './pages/ProfilePages/MyConfiguration'
import ComingSoon from './pages/ProfilePages/ConfigurationPages/ComingSoon'
import PageNotFound from './components/PageNotFound/PageNotFound'
import BusinessMenu from './pages/BusinessMenu'
import ConfirmOrder from './pages/ConfirmOrder'
import OrdersStatus from './pages/ProfilePages/OrdersStatus'
import ShowQR from './pages/ProfilePages/ShowQR'
import ShowInvoice from './pages/ProfilePages/ShowInvoice'
import OrderAsWaiter from './pages/ProfilePages/OrderAsWaiter'
import KitchenModule from './pages/ProfilePages/KitchenModule'
import Invoices from './pages/Invoices'

function App () {
  return (
    <>
      <Routes>
        <Route index path='/login' element={<RouteWithoutUserSession> <Login /> </RouteWithoutUserSession>} />
        {/* <Route path='/register' element={<RouteWithoutUserSession> <Register /> </RouteWithoutUserSession>} />
        <Route path='/forgot-password' element={<RouteWithoutUserSession> <ForgotPassword /> </RouteWithoutUserSession>} />
        <Route path='/reset-password' element={<RouteWithoutUserSession> <ResetPassword /> </RouteWithoutUserSession>} /> */}
        <Route path='not-found' element={<PageNotFound />} />

        <Route path='/:business_username' element={<BusinessMenu />} />
        {/* Hay que crear una ruta protegida especial para Customer, porque la que tenemos actualmente maneja loggedUser */}
        <Route path='/:business_username/pedido' element={<ConfirmOrder />} />

        <Route path='/' element={<Navigate to='/dashboard/table-management' replace />} />
        <Route element={<ProtectedRoute> <Layout /> </ProtectedRoute>}>
          <Route path='/dashboard/table-management' element={<TableManagement />} />
          <Route path='/dashboard/orders-status' element={<OrdersStatus />} />
          <Route path='/dashboard/kitchen-module' element={<KitchenModule />} />
          <Route path='/dashboard/invoices' element={<Invoices />} />
          <Route path='/dashboard/table-management/qr/:id' element={<ShowQR />} />
          <Route path='/dashboard/table-management/take-note/:id' element={<OrderAsWaiter />} />
          <Route path='/dashboard/table-management/invoice/:id' element={<ShowInvoice />} />
          <Route path='/account/configuration' element={<MyConfiguration />} />
          <Route path='/account/configuration/coming-soon' element={<ComingSoon />} />
        </Route>

      </Routes>
    </>
  )
}

export default App
