import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'
import SimpleCardProduct from '../../components/SimpleCard/SimpleCardProduct'
import { getCartQuantity, handleAddToCart, handleRemoveFromCart } from '../../utils/MenuFunctions'
import Footer from '../../components/Footer/Footer'
import { AuthContext } from '../../context/AuthContext'

const businessService = require('../../services/businessService')

const OrderAsWaiter = () => {
  const { user } = useContext(AuthContext)
  const username = user.username

  const navigate = useNavigate()
  const tableNumber = useParams().id

  const [cart, setCart] = useState([])
  const [loading, setLoading] = useState(true)
  const [businessInfo, setBusinessInfo] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      try {
        const data = await businessService.getOneBusinessPublicInfo(username)
        setBusinessInfo(data)
      } catch (error) {
        navigate('not-found')
      }

      setLoading(false)
    }
    fetchData()
  }, [tableNumber, navigate, username])

  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <>
      <div className='relative m-2'>
        <input
          type='search'
          className='bg-purple-white shadow rounded border-0 p-3'
          placeholder='Buscar producto...'
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {businessInfo.products &&
      businessInfo.products
        .filter((product) => {
          const productNameLowerCase = product.name.toLowerCase()
          const searchTermLowerCase = searchTerm.toLowerCase()

          // Remove accents and diacritics to make the search insensitive to them
          const normalizedProductName = productNameLowerCase.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

          return normalizedProductName.includes(searchTermLowerCase)
        })
        .map((product, index) => {
          return (
            <div key={product._id}>
              <SimpleCardProduct product={product} addToCart={() => handleAddToCart(product, cart, setCart)} removeFromCart={() => handleRemoveFromCart(product, cart, setCart)} cart={cart} />
            </div>
          )
        })}

      <Footer cartSize={getCartQuantity(cart)} order={cart} businessUsername={username} customer={false} tableNumber={tableNumber} />
    </>
  )
}

export default OrderAsWaiter
