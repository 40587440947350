import React, { useEffect, useReducer, useState } from 'react'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'
import { calculateMinutesFromDateToNow, isProductChecked, compareOldOrderListWithNew, compareStringDatesFromOrders, getOptionsFromProduct, handleCheckButtonClick, loadOrderStatus, sendNotification, showCommentDiv, updateOrderStatus, changeProductStatus } from '../../utils/orderManagement'
import { useMinutesManager } from '../../utils/TimeFunctions'
import CheckButton from '../../smallComponents/Buttons/CheckButton'

const businessService = require('../../services/businessService')

export default function KitchenModule () {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderStatus, setOrderStatus] = useReducer(updateOrderStatus, {})

  useEffect(() => {
    let previousOrders = [] // Almacena las órdenes anteriores
    let firstTimeExecuted = false

    async function fetchData () {
      setLoading(false)

      try {
        const data = await businessService.getOrdersFromBusiness('pending')

        // Compara las órdenes actuales con las órdenes anteriores antes de actualizar el estado
        const comparationResult = compareOldOrderListWithNew(previousOrders, data.orders)
        if (comparationResult[0]) {
          setOrders(data.orders)

          if (comparationResult[1] && firstTimeExecuted) {
            sendNotification()
          }
        }

        if (!firstTimeExecuted) {
          loadOrderStatus(data.orders, setOrderStatus)
          firstTimeExecuted = true
        }

        // setLoading(false)
        previousOrders = data.orders // Actualiza las órdenes anteriores
      } catch (error) {
        // Manejo de errores si es necesario
      }
    }

    fetchData() // Llama a fetchData al montar el componente

    // Configura un intervalo para llamar a fetchData
    const interval = setInterval(fetchData, 5000)

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(interval)
  }, [])

  // Utiliza la función useMinutesManager para gestionar los minutos
  const { minutes, updateMinute } = useMinutesManager({})
  // Actualiza el valor de los minutos cuando se monta el componente
  useEffect(() => {
    orders.forEach((order, index) => {
      const minutesValue = calculateMinutesFromDateToNow(order.last_modified)
      updateMinute(index, minutesValue) // Actualiza el valor de los minutos
    })
  }, [orders, updateMinute])

  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <>

      <div className='grid grid-cols-4 gap-4 mb-5 px-3'>
        {orders
          .sort(compareStringDatesFromOrders)
          .map((order, index) => (
            <div key={index} className='relative'>
              <section
                className='border border-gray-100 bg-white rounded-lg shadow-lg mt-2 w-full hover:shadow-2xl transition py-4'
              >

                <div
                  className='mx-3 space-y-5'
                >
                  <span className='px-4 py-2 bg-slate-800 text-white text-m font-medium rounded'>{order.priority === 1 ? 'Primeros' : order.priority === 2 ? 'Segundos' : order.priority === 3 ? 'Postres' : ''}</span>
                  <span className='text-l font-medium ml-5'>Mesa {order.table_number}</span>
                  {order.products && order.products
                    .map((product, index) => (

                      <div
                        key={index}
                        className='text-l lg:text-xl text-slate-800 space-x-2'
                      >
                        <span className='text-gray-700'>{product.quantity ?? 1}</span>
                        <span className={`${isProductChecked(orderStatus[order._id], product._id) ? 'line-through text-gray-500' : ''}`} onClick={() => changeProductStatus(order, product, orderStatus, setOrderStatus)}>{product.name}</span>
                      </div>

                    ))}
                  <div className='flow-root'>
                    <CheckButton
                      props={{ title: '' }}
                      onClick={() => handleCheckButtonClick(order, 'toReady', { orders, setOrders })}
                    />

                    <div className={`float-right flex items-center mt-2 ${calculateMinutesFromDateToNow(order.last_modified) < 20 ? 'text-green-500' : 'text-orange-500'}`}>
                      <svg className='h-5 w-5 mr-1' width='24' height='24' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>  <path stroke='none' d='M0 0h24v24H0z' />  <circle cx='12' cy='12' r='9' />  <polyline points='12 7 12 12 15 15' /></svg>
                      <span id='target' className='inherit-text'>{minutes[index]} min</span>
                    </div>
                  </div>

                </div>

              </section>

              <div
                className='absolute top-0 right-6 transform translate-y-1/4 w-3.5 h-3.5'
                onClick={() => setOrderStatus({ orderId: order._id, fire: !(orderStatus[order._id] ?? {}).fire })}
              >
                <svg className={`h-12 w-12 ${(orderStatus[order._id] ?? {}).fire ? 'text-orange-500' : 'text-gray-400'}`} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z' />
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z' />
                </svg>
              </div>

            </div>
          ))}

      </div>

    </>
  )
}
