import React, { useState, useEffect, useContext, Fragment } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { AuthContext } from '../context/AuthContext'
import noAvatar from '../resources/images/noAvatar.png'
// import BetaButton from '../smallComponents/Buttons/BetaButton'

const authService = require('../services/authService')

const navigation = [
  { name: 'Gestión de mesas', href: '/dashboard/table-management', current: false },
  { name: 'Pedidos', href: '/dashboard/orders-status', current: false },
  { name: 'Módulo de cocina', href: '/dashboard/kitchen-module', current: false },
  { name: 'Configuración', href: '/account/configuration', current: false }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavActiveClassName =
  'bg-slate-800 text-white px-3 py-2 rounded-md text-sm font-medium'
const NavDefaultClassName =
  'text-black-600 hover:bg-slate-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium'

const NavMenu = ({ profilePhoto }) => {
  const navigate = useNavigate()
  const [userAvailable, setUserAvailable] = useState(false)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (user) {
      setUserAvailable(true)
    }
  }, [user])

  return (
    <Disclosure as='nav' className='bg-white border-b-gray-300 border'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-black-600 hover:bg-slate-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open
                    ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                      )
                    : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                      )}
                </Disclosure.Button>

              </div>

              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <NavLink
                        to={item.href}
                        key={item.href}
                        className={({ isActive }) =>
                          isActive ? NavActiveClassName : NavDefaultClassName}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* <Link to='/publish'>
                  <button
                    type='button'
                    className='rounded-full bg-indigo-400 p-1 text-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                  >
                    <span className='sr-only'>Publish travel</span>
                    <PlusCircleIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </Link>
                <Link to='/notifications'>
                  <button
                    type='button'
                    className='ml-1 rounded-full bg-indigo-400 p-1 text-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                  >
                    <span className='sr-only'>View notifications</span>
                    <BellIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </Link> */}

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex rounded-full bg-black-600 text-sm align-middle items-center'>
                      <span className='sr-only'>Open user menu</span>
                      {userAvailable
                        ? (
                          <img
                            className='h-11 w-11 rounded-full'
                            src={profilePhoto}
                            alt='logo'
                          />
                          )
                        : (
                          <img
                            className='h-11 w-11 rounded-full'
                            src={noAvatar}
                            alt='logo'
                          />
                          )}
                      <svg className='h-5 w-5 text-slate-900' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>  <polyline points='6 9 12 15 18 9' /></svg>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        <p className='block px-4 py-2 text-xs text-gray-500'>
                          {user.email}
                        </p>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to='/account/configuration'
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Configuración
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            onClick={() => authService.logoutUser(navigate)}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Cerrar sesión
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigation.map((item) => (
                <Disclosure.Button
                  as={NavLink}
                  to={item.href}
                  key={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-white-900 text-black'
                      : 'text-gray-700 hover:bg-slate-500 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default NavMenu
