import axios from 'axios'
import { refreshSession } from '../services/refreshTokenService'
import { dbapiEndpoint } from '../utils/httpVars'
import { logoutUser } from '../services/authService'

// axios.defaults.baseURL = `${dbapiEndpoint}/api/v1/`
const axiosPrivate = axios.create({
  baseURL: `${dbapiEndpoint}/api/v1/`
})

axiosPrivate.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true
      console.log('Intentando refrescar token..')

      const refreshToken = localStorage.getItem('refreshToken')

      localStorage.removeItem('accessToken')
      config.headers.Authorization = ''

      try {
        const accessToken = await refreshSession(refreshToken)
        config.headers.Authorization = accessToken

        return axiosPrivate(config)
      } catch (error) {
        await logoutUser()
      }
    }
    return Promise.reject(error)
  }
)

export default axiosPrivate
