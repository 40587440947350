import axiosPrivate from '../middleware/axiosInterceptor'
import axios from 'axios'
import { dbapiEndpoint, config, configwithauthorization } from '../utils/httpVars'

const getOneBusinessPublicInfo = async (username) => {
  try {
    const res = await axios.get(`${dbapiEndpoint}/api/v1/business/${username}/public`, config)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Debe iniciar sesión para obtener su información de perfil')
    } else {
      console.error('Se produjo un error al obtener su información de perfil. Por favor, inténtelo de nuevo más tarde.')
    }
  }
}

const getOrdersFromBusiness = async (status) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/orders?status=${status}`, configwithauthorization)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Debe iniciar sesión para obtener esta información')
    } else {
      console.error('Error en getOrdersFromBusiness.')
    }
  }
}

const updateOrder = async (orderId, obj) => {
  try {
    const res = await axiosPrivate.put(`${dbapiEndpoint}/api/v1/business/orders/${orderId}`,
      {
        status: obj.status
      },
      configwithauthorization)
    return res.data
  } catch (error) {
    console.error(error)
  }
}

const getBusinessInfoById = async (userId) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/id/${userId}`, config)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getUserInfoById) from backend: ${error}`)
    throw error
  }
}

const getAllOrdersByTableNumber = async (tableNumber) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/total-orders/${tableNumber}`, configwithauthorization)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getAllOrdersByTableNumber) from backend: ${error}`)
    throw error
  }
}

const getTotalFromTable = async (tableNumber) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/total/${tableNumber}`, configwithauthorization)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getInvoice) from backend: ${error}`)
    throw error
  }
}

const getAllBillings = async (tableNumber) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/invoices/${tableNumber}`, configwithauthorization)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getInvoice) from backend: ${error}`)
    throw error
  }
}

const getBillingsByDates = async (formData) => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/business/billings`, configwithauthorization)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getBillingsByDates) from backend: ${error}`)
    throw error
  }
}

export {
  getOneBusinessPublicInfo,
  getOrdersFromBusiness,
  updateOrder,
  getBusinessInfoById,
  getAllOrdersByTableNumber,
  getTotalFromTable,
  getAllBillings,
  getBillingsByDates
}
