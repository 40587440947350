import Swal from 'sweetalert2'
import notificationSound from '../resources/sounds/new_order_notification.mp3'
import { clientTimezoneOffset } from './TimeFunctions'
const businessService = require('../services/businessService')

const notificationSoundPlayer = new Audio(notificationSound)
notificationSoundPlayer.load()

/**
 * @param {Array} oldList
 * @param {Array} newList
 * @returns {Array} El primer valor indica si se tiene que actualizar la lista de pedidos, el segundo indica si alguno de los pedidos es nuevo
 */
function compareOldOrderListWithNew (oldList, newList) {
  // Si la lista nueva es mas grande devuelve todo a true porque hay algun pedido nuevo
  if (oldList.length < newList.length) {
    return [true, true]
  }

  let newProducts = false
  let updateList = false

  // Recoge solo los IDs de los pedidos
  const oldIds = getIdsFromOrderList(oldList)
  const newIds = getIdsFromOrderList(newList)

  newIds.forEach((orderId) => {
    // Comprueba si la vieja lista contiene el ID
    if (!oldIds.includes(orderId)) {
      updateList = newProducts = true
    }
  })

  // Si la lista vieja en mas grande en este punto, significa que un pedido de antes ya no esta
  if (oldList.length > newList.length) {
    updateList = true
  }

  // Si el primero es true se tiene que actualizar la lista de pedidos, si el segundo es true es porque hay algun pedido nuevo
  return [updateList, newProducts]
}

/**
* @param {Array} orderList
* @returns {Array}
*/
function getIdsFromOrderList (orderList) {
  return orderList.map(order => order._id)
}

async function sendNotification () {
  const notificationMessage = 'Nuevo pedido entrante'
  notificationSoundPlayer.play().catch(() => false)
  Swal.fire({
    position: 'top',
    text: notificationMessage,
    showConfirmButton: false,
    toast: true,
    timer: 5000
  })

  if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
    await Notification.requestPermission()
  }

  if (Notification.permission === 'granted') {
    const notification = new Notification(notificationMessage)
    notification.close()
  }
}

async function handleCheckButtonClick (order, status, { orders, setOrders }) {
  let updatedStatus

  if (status === 'toReady') {
    updatedStatus = 'ready'
    order.status = 'ready'
  } else if (status === 'toDelivered') {
    updatedStatus = 'delivered'
    order.status = 'delivered'
  }

  await businessService.updateOrder(order._id, { status: updatedStatus })

  setOrders([...orders].filter(element => element._id !== order._id))
}

function compareStringDatesFromOrders (orderOne, orderTwo) {
  const dateOne = new Date(orderOne.last_modified)
  const dateTwo = new Date(orderTwo.last_modified)

  if (dateOne < dateTwo) {
    return -1
  } else {
    return 1
  }
}

function getOptionsFromProduct (product) {
  let { options } = product

  options = options ?? []

  if (options.length === 0) {
    return null
  }

  return <p>{options.join(', ')}</p>
}

/**
 * @param {string} comment
 * @returns {string}
 */
function showCommentDiv (comment) {
  if (comment === null || comment === undefined || comment.trim() === '') {
    return ''
  }

  return (
    <div className='mx-2 p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'>
      {comment}
    </div>
  )
}

/**
 * @param {EventTarget} productElement
 * @param {TouchList} doAction
 */
function strikeOutProduct (productElement, touches) {
  productElement.classList.toggle('line-through')
  productElement.classList.toggle('text-gray-500')
}

/**
 * @param {String} dateString
 * @returns {Number}
 */
function calculateMinutesFromDateToNow (dateString) {
  const date = new Date(dateString)
  date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000))
  let minutes = new Date().getTime() - date.getTime()
  minutes = minutes + clientTimezoneOffset()
  minutes = minutes / 1000 / 60
  return Math.round(minutes)
}

/**
 * @param {Object} orderStatus
 * @param {Object} newStatus
 * @returns {Object}
 */
function updateOrderStatus (orderStatus, newStatus) {
  if (Object.prototype.hasOwnProperty.call(newStatus, 'product')) {
    orderStatus[newStatus.orderId] = {
      ...orderStatus[newStatus.orderId],
      products: {
        ...(orderStatus[newStatus.orderId] ?? {}).products,
        [newStatus.product.id]: { checked: newStatus.product.checked }
      }
    }
  }

  if (Object.prototype.hasOwnProperty.call(newStatus, 'fire')) {
    orderStatus[newStatus.orderId] = {
      ...orderStatus[newStatus.orderId],
      fire: newStatus.fire
    }
  }

  sessionStorage.setItem('orderStatus', JSON.stringify(orderStatus))
  return { ...orderStatus }
}

/**
 * @param {Array} orders
 * @param {Function} setOrderStatus
 */
function loadOrderStatus (orders, setOrderStatus) {
  const sessionOrderStatus = JSON.parse(sessionStorage.getItem('orderStatus'))

  orders.forEach(order => {
    const actualStatus = sessionOrderStatus[order._id] ?? {}
    setOrderStatus({ orderId: order._id, fire: actualStatus.fire })
    Object.keys(actualStatus.products).forEach(productId => setOrderStatus({ orderId: order._id, product: { id: productId, checked: actualStatus.products[productId].checked } }))
  })
}

function isProductChecked (orderStatus, productId) {
  const { products } = (orderStatus ?? {})

  if (products === undefined) {
    return false
  }

  return (products[productId] ?? {}).checked
}

function changeProductStatus (order, product, orderStatus, setOrderStatus) {
  setOrderStatus({
    orderId: order._id,
    product: {
      id: product._id,
      checked: !isProductChecked(orderStatus[order._id], product._id)
    }
  })
}

export {
  compareOldOrderListWithNew,
  getIdsFromOrderList,
  sendNotification,
  handleCheckButtonClick,
  compareStringDatesFromOrders,
  getOptionsFromProduct,
  showCommentDiv,
  strikeOutProduct,
  calculateMinutesFromDateToNow,
  updateOrderStatus,
  loadOrderStatus,
  isProductChecked,
  changeProductStatus
}
