import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import WhiteDiv from '../../components/WhiteDiv/WhiteDiv'
import InvoiceButton from '../../smallComponents/Buttons/InvoiceButton'
import OrderPageSwitcher from '../../smallComponents/Buttons/OrderPageSwitcher'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'

const businessService = require('../../services/businessService')
const customerService = require('../../services/customerService')

const TableManagement = () => {
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(true)
  const { user } = useContext(AuthContext)

  const navigate = useNavigate()
  // const tableNumbers = [1, 2, 3, 5]

  async function fetchData () {
    setLoading(true)
    try {
      const data = await customerService.getAllCustomers()
      if (data) {
        setCustomers(data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [user.email])

  const handleClickInvoice = (tableNumber) => {
    navigate(`/dashboard/table-management/invoice/${tableNumber}`)
  }

  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <>
      <WhiteDiv title='Gestión de mesas'>
        <div className='grid grid-cols-1 gap-4'>
          {customers.map((customer) => {
            const serviceInProgress = customer.service_in_progress
            return (
              <div
                key={customer.table_number}
                className='bg-white border border-gray-100 shadow-lg p-4 rounded-lg text-center space-y-1 flex justify-between items-center'
              >
                <span className='text-left'>{`Mesa ${customer.table_number}`}</span>

                <div className='text-right space-x-1'>
                  {serviceInProgress && <InvoiceButton onClick={() => handleClickInvoice(customer.table_number)} />}
                  <OrderPageSwitcher tableNumber={customer.table_number} />
                </div>
              </div>
            )
          })}
        </div>
      </WhiteDiv>

    </>
  )
}

export default TableManagement
