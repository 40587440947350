import React from 'react'

const InvoiceElement = ({ product }) =>
  <tr>
    <td className='text-left text-gray-700'>{product.quantity}</td>
    <td className='text-left text-gray-700'>{product.name}</td>
    <td className='text-right text-gray-700'>{product.price.toFixed(2)}</td>
    <td className='text-right text-gray-700'>{product.total_price.toFixed(2)}</td>
  </tr>

export default InvoiceElement
