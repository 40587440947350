import { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import LoadingSpinner from '../smallComponents/LoadingSpinner/LoadingSpinner'

const customerService = require('../services/customerService')
const businessService = require('../services/businessService')

const Invoices = () => {
  const { register, handleSubmit, formState: { errors } } = useForm()

  const [loading, setLoading] = useState(true)
  const [tables, setTables] = useState([])

  const startDateRef = useRef('')
  const endDateRef = useRef('')
  const tableNumberRef = useRef('')

  useEffect(() => {
    (async function () {
      const data = await customerService.getAllCustomers()
      if (data) {
        setTables(data)
        setLoading(false)
      }
    })()
  })

  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <>
      <form
        className='mt-8 space-y-6'
        method='POST'
        onSubmit={handleSubmit(businessService.searchBillings)}
      >
        <div className='flex'>
          <input
            type='date'
            ref={startDateRef}
            onChange={(event) => endDateRef.current.setAttribute('min', event.target.value)}
            {...register('start_date', {
              required: true
            })}
          />
          <input
            type='date'
            ref={endDateRef}
            onChange={(event) => startDateRef.current.setAttribute('max', event.target.value)}
            {...register('end_date', {
              required: true
            })}
          />
          <select ref={tableNumberRef}>
            <option value={null} />
            {tables.map((table) => (
              <option key={table._id} value={table.table_number}>
                {table.table_number}
              </option>
            ))}
          </select>
          <button
            className='px-4 py-2 bg-green-500 hover:bg-green-500 text-white text-sm font-medium rounded-md'
            type='submit'
          >
            Buscar
          </button>
        </div>
      </form>
    </>
  )
}

export default Invoices
