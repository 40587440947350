import { useNavigate } from 'react-router-dom'
import { React } from 'react'
import logo from '../../resources/images/logo.png'

const authService = require('../../services/authService')

const Login = () => {
  const navigate = useNavigate()

  return (
    <>
      <img
        className='w-32 ml-0 md:ml-10'
        src={logo}
        alt='Plis logo'
      />

      <div className='flex items-center mt-10 bg-white dark:bg-gray-900'>
        <div className='container mx-auto'>
          <div className='max-w-md mx-auto my-10'>
            <div className='text-center'>
              <h1 className='my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200'>Acceso para restaurantes</h1>
              <p className='text-gray-500 dark:text-gray-400'>Nos alegra verte de nuevo</p>
            </div>
            <div className='m-7'>
              <form
                action='#' method='POST'
                onSubmit={(e) => authService.sendLogin(e, navigate)}
              >
                <div className='mb-6'>
                  <label for='email' className='block mb-2 text-sm text-gray-600 dark:text-gray-400'>Correo electrónico</label>
                  <input type='email' name='email' id='email' placeholder='restaurante@gmail.com' className='w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500' />
                </div>
                <div className='mb-6'>
                  <div className='flex justify-between mb-2'>
                    <label for='password' className='text-sm text-gray-600 dark:text-gray-400'>Contraseña</label>
                    <a href='#!' className='text-sm text-gray-400 focus:outline-none hover:text-blue-500 dark:hover:text-blue-300'>¿Contraseña olvidada?</a>
                  </div>
                  <input type='password' name='password' id='password' placeholder='Tu contraseña' className='w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500' />
                </div>
                <div className='mb-6'>
                  <button type='submit' className='w-full px-3 py-4 text-white bg-slate-800 rounded-md hover:bg-slate-700'>Entrar</button>
                </div>
                <p className='text-sm text-center text-gray-500'>¿No tienes cuenta todavía? Visita nuestra web y solicita <a href='https://www.plisapp.com' className='text-blue-500 focus:outline-none focus:underline focus:text-blue-500 dark:focus:border-blue-800'> tu Carta digital con pedido en mesa</a>.</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
