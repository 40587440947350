import React, { useState, useEffect } from 'react'
import { renderAllergenIcons } from '../../utils/MenuFunctions'

export default function SimpleCardProduct ({ tableOrdering, product, addToCart, removeFromCart, cart }) {
  const [isInCart, setIsInCart] = useState(false)
  const [quantityInCart, setQuantityInCart] = useState(0)

  useEffect(() => {
    setIsInCart(checkIfInCart(product))
    setQuantityInCart(getQuantityInCart(product))
  }, [product, cart])

  const handleAddToCart = () => {
    addToCart(product)
    setIsInCart(true)
    setQuantityInCart(quantityInCart + 1)
  }

  const handleRemoveFromCart = () => {
    removeFromCart(product)
    setIsInCart(false)
    setQuantityInCart(quantityInCart - 1)
  }

  const checkIfInCart = (product) => {
    return cart.some((item) => item._id === product._id)
  }

  const getQuantityInCart = (product) => {
    const cartProduct = cart.find((item) => item._id === product._id)
    return cartProduct ? cartProduct.quantity : 0
  }

  return (
    <section className='border-b border-gray-300 bg-white w-full transition mt-2 pt-2'>
      <div className='p-2 flex flex-col justify-center items-center'>
        <div className='flex px-2 space-x-2 w-full'>
          <div className='text-left space-y-2 w-full'>
            <h4 className='font-medium text-l'>{product.name}</h4>
            {product.description
              ? (
                <p className='text-gray-600 text-sm'>
                  {product.description}
                </p>
                )
              : null}
            {product.allergens
              ? (
                <span className='flex'>{renderAllergenIcons(product.allergens)}</span>
                )
              : null}
          </div>
          {product.image_url
            ? (
              <div className='flex flex-grow-0 flex-shrink-0 w-36'>
                <img src={product.image_url} alt='' className='rounded-2xl w-full h-auto' />
              </div>
              )
            : null}
        </div>
      </div>

      <div className='py-2 pr-6 flex justify-between w-full items-baseline'>
        <div className='ml-4 mr-auto text-left flex flex-col'>
          <h4 className='text-gray-700 text-sm'> {product.price.toFixed(2)} €</h4>
        </div>

        {tableOrdering === true && (
          isInCart
            ? (
              <div className='h-10 w-28'>

                <div className='flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1'>
                  <button onClick={handleRemoveFromCart} className=' bg-black text-white hover:bg-gray-900 h-full w-20 rounded-l cursor-pointer'>
                    <span className='m-auto text-2xl font-light'>−</span>
                  </button>
                  <span className='justify-center text-center w-full bg-gray-100 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-900'>
                    {quantityInCart}
                  </span>
                  <button onClick={handleAddToCart} className='bg-black text-white hover:bg-gray-900 h-full w-20 rounded-r cursor-pointer'>
                    <span className='m-auto text-2xl font-light'>+</span>
                  </button>
                </div>
              </div>
              )
            : (
              <button
                className='py-2 px-2 text-lg bg-black text-white rounded-full hover:bg-gray-900'
                onClick={handleAddToCart}
              >
                <svg
                  className='h-6 w-6 text-white'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  {' '}
                  <line x1='12' y1='5' x2='12' y2='19' />{' '}
                  <line x1='5' y1='12' x2='19' y2='12' />
                </svg>
              </button>
              )
        )}
      </div>
    </section>
  )
}
