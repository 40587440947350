import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleCard from '../components/SimpleCard/SimpleCard'
import GoBackButton from '../smallComponents/GoBackButton/GoBackButton'

const customerService = require('../services/customerService')

export default function ConfirmOrder () {
  const navigate = useNavigate()
  const location = useLocation()
  const [comment, setComment] = useState('')
  const { productDetails, customer, tableNumber } = location.state

  const handleConfirmOrder = async (productDetails) => {
    if (customer === false) {
      await customerService.createOrderAsBusiness(productDetails, tableNumber, comment)
    } else {
      await customerService.createOrder(productDetails, comment)
    }
    navigate(-1)
  }

  return (
    <>
      <div className='mb-16'>
        <section className='border-none bg-black shadow-lg w-full max-w-[480px] transition pt-2 lg:pt-24'>
          <div className='p-4 flex flex-col justify-center items-center '>
            <div className='flex justify-between w-full items-baseline'>
              <div className='ml-4 mr-auto text-left flex flex-col'>
                <h4 className='font-semibold text-white text-xl'>Revisa tu pedido</h4>
              </div>
              <GoBackButton />
            </div>
          </div>
        </section>

        {productDetails.map((product) => (
          <SimpleCard key={product._id} product={product} />
        ))}

      </div>
      <section className='fixed bottom-0 left-0 right-0 border border-gray-100 bg-white shadow-lg w-full max-w-[480px]'>
        <div className='m-3'>
          <textarea
            id='comment' className='w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
            placeholder='¿Alguna nota especial para cocina? Ejemplos: "Sin cebolla", "Punto de la carne: hecha", "Salsa aparte", etc..'
            onChange={event => setComment(event.target.value)}
          />
        </div>

        <div className='flex justify-center space-x-5'>
          <button onClick={() => handleConfirmOrder(productDetails)} className='bg-black text-white m-1 py-2 px-4 rounded'>Confirmar y enviar a la cocina</button>
        </div>
      </section>
    </>
  )
}
