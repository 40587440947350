
const dbapiEndpoint = process.env.REACT_APP_DBAPI_ENDPOINT
const paymentapiEndpoint = process.env.REACT_APP_PAYMENTAPI_ENDPOINT

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

const configwithauthorization = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('accessToken')
  }
}

const getConfigWithAuthorization = async () => {
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('accessToken')
    }
  }
}

export {
  dbapiEndpoint,
  paymentapiEndpoint,
  config,
  configwithauthorization,
  getConfigWithAuthorization
}
