import { Dropdown } from 'flowbite-react'
import React, { useEffect, useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import WhiteDiv from '../../components/WhiteDiv/WhiteDiv'
import CheckButton from '../../smallComponents/Buttons/CheckButton'
import FilterButton from '../../smallComponents/Buttons/FilterButton'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'
import { calculateMinutesFromDateToNow, changeProductStatus, compareOldOrderListWithNew, compareStringDatesFromOrders, getOptionsFromProduct, handleCheckButtonClick, isProductChecked, loadOrderStatus, sendNotification, showCommentDiv, strikeOutProduct, updateOrderStatus } from '../../utils/orderManagement'
import { useMinutesManager } from '../../utils/TimeFunctions'

const businessService = require('../../services/businessService')

export default function OrdersStatus () {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState(null)
  const [orderStatus, setOrderStatus] = useReducer(updateOrderStatus, {})

  useEffect(() => {
    let previousOrders = [] // Almacena las órdenes anteriores
    let firstTimeExecuted = false

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const newFilter = urlParams.get('status')
    setFilter(newFilter) // Actualiza el valor del filtro en el estado

    async function fetchData () {
      setLoading(false)

      try {
        const data = await businessService.getOrdersFromBusiness(filter)

        // Compara las órdenes actuales con las órdenes anteriores antes de actualizar el estado
        const comparationResult = compareOldOrderListWithNew(previousOrders, data.orders)
        if (comparationResult[0]) {
          setOrders(data.orders)

          if (comparationResult[1] && firstTimeExecuted) {
            sendNotification()
          }
        }

        if (!firstTimeExecuted) {
          loadOrderStatus(data.orders, setOrderStatus)
          firstTimeExecuted = true
        }

        // setLoading(false)
        previousOrders = data.orders // Actualiza las órdenes anteriores
      } catch (error) {
        // Manejo de errores si es necesario
      }
    }

    fetchData() // Llama a fetchData al montar el componente

    // Configura un intervalo para llamar a fetchData
    const interval = setInterval(fetchData, 5000)

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(interval)
  }, [filter])

  // Utiliza la función useMinutesManager para gestionar los minutos
  const { minutes, updateMinute } = useMinutesManager({})
  // Actualiza el valor de los minutos cuando se monta el componente
  useEffect(() => {
    orders.forEach((order, index) => {
      const minutesValue = calculateMinutesFromDateToNow(order.last_modified)
      updateMinute(index, minutesValue) // Actualiza el valor de los minutos
    })
  }, [orders, updateMinute])

  if (loading === true) {
    return <LoadingSpinner />
  }

  const statuses = ['pending', 'ready', 'delivered']

  if (filter === null) {
    setFilter(localStorage.getItem('lastView') ?? statuses[0])
  }
  localStorage.setItem('lastView', filter)

  return (
    <>
      <WhiteDiv title='Pedidos'>
        <div className='flex space-x-5'>
          <Dropdown
            label={<FilterButton title='Vista' />}
            inline
          >
            {statuses
              .map((status, index) => (
                <Dropdown.Item key={index} className={status === filter ? 'bg-neutral-200' : ''}>
                  <Link className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300' to={{ search: `?status=${status}` }} reloadDocument='true'>
                    {status === 'pending' && 'Vista de cocinero'}
                    {status === 'ready' && 'Vista de camarero'}
                    {status === 'delivered' && 'Comandas completadas'}
                  </Link>
                </Dropdown.Item>
              ))}
          </Dropdown>
        </div>

        {orders
          .sort(compareStringDatesFromOrders)
          .map((order, index) => (
            <section
              key={index}
              className='border border-gray-100 bg-white rounded-lg shadow-lg mt-2 w-full hover:shadow-2xl transition py-4'
            >

              <div
                className='mx-3 space-y-3'
              >
                <span className='text-l font-medium'>
                  Mesa {order.table_number}
                </span>
                {order.products && order.products
                  .map((product, productIndex) => (

                    <h4
                      key={productIndex}
                      className={`text-l lg:text-xl text-slate-800 space-x-2 ${isProductChecked(orderStatus[order._id], product._id) ? 'line-through text-gray-500' : ''}`}
                    >
                      <span className='text-gray-700'>{product.quantity ?? 1}</span>
                      <span onClick={() => changeProductStatus(order, product, orderStatus, setOrderStatus)}>{product.name}</span>
                    </h4>

                  ))}
                <div className='flow-root'>
                  {order.status === 'pending' && (
                    <CheckButton
                      props={{ title: 'Marcar como cocinado' }}
                      onClick={() => handleCheckButtonClick(order, 'toReady', { orders, setOrders })}
                    />
                  )}
                  {order.status === 'ready' && (
                    <CheckButton
                      props={{ title: 'Marcar como entregado' }}
                      onClick={() => handleCheckButtonClick(order, 'toDelivered', { orders, setOrders })}
                    />
                  )}

                  {order.status !== 'delivered' && (
                    <div className={`float-right flex items-center mt-2 ${calculateMinutesFromDateToNow(order.last_modified) < 20 ? 'text-green-500' : 'text-orange-500'}`}>
                      <svg className='h-5 w-5 mr-1' width='24' height='24' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>  <path stroke='none' d='M0 0h24v24H0z' />  <circle cx='12' cy='12' r='9' />  <polyline points='12 7 12 12 15 15' /></svg>
                      <span className='inherit-text'>{minutes[index]} min</span>
                    </div>
                  )}

                </div>

              </div>

            </section>

          ))}

      </WhiteDiv>
    </>
  )
}
