import React from 'react'
import { useNavigate } from 'react-router-dom'

const AccountRecoveryService = require('../../services/AccountRecoveryService')

const ForgotPassword = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl'>
          <div className='max-w-md mx-auto space-y-6 my-4'>
            <h2 className='items-center font-semibold text-lg'>
              Recupera tu cuenta
            </h2>
            <span>
              Ingresa tu correo electrónico para enviarte un enlace de
              recuperación.
            </span>
            <form
              className='space-y-6'
              action='#'
              method='POST'
              onSubmit={(e) => AccountRecoveryService.sendRecoveryLink(e)}
            >
              <input
                name='email'
                type='email'
                className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 sm:text-sm'
                placeholder='Correo electrónico'
              />

              <div className='flex flex-row-reverse'>
                <button
                  type='submit'
                  className='rounded-md shadow-s bg-indigo-500 text-white font-semibold py-3 px-5 mr-3'
                >
                  Enviar
                </button>

                <button onClick={() => navigate(-1)} className='rounded-md shadow-s bg-slate-200 text-slate-600 font-semibold p-3 mr-3'>
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
