import axios from 'axios'
import Swal from 'sweetalert2'
import { dbapiEndpoint, config, getConfigWithAuthorization } from '../utils/httpVars'
import { getCustomersFromBusiness } from './customerService'
const stringManipulation = require('../utils/stringManipulation')

const sendRegister = async (e) => {
  try {
    e.name = stringManipulation.capitalizeName(e.name)

    await axios.post(`${dbapiEndpoint}/api/v1/auth/register`, {
      email: e.email_address,
      name: e.name,
      password: e.password,
      birth_date: e.birth_date,
      genre: e.genre
    }, config)

    await Swal.fire({
      title: '¡Bienvenid@!',
      text: 'Se te ha enviado un correo para verificar y acceder a tu cuenta.',
      icon: 'success',
      confirmButtonText: 'OK'
    })

    window.location.href = '/login'
  } catch (error) {
    if (error.response && error.response.status === 401) {
      alert('Correo o contraseña incorrecta')
    } else {
      alert('Ocurrió un error al intentar iniciar sesión')
    }
  }
}

const sendLogin = async (e) => {
  try {
    e.preventDefault()
    const res = await axios.post(`${dbapiEndpoint}/api/v1/auth/loginB`, {
      email: e.target.email.value,
      password: e.target.password.value
    }, config)

    console.log(res.data)

    window.localStorage.setItem('accessToken', res.data.accessToken)
    window.localStorage.setItem('refreshToken', res.data.refreshToken)

    // Set Customers after login
    const customers = await getCustomersFromBusiness()

    const persist = {
      _id: res.data._id,
      profile_photo: res.data.profile_photo,
      username: res.data.username,
      email: res.data.email,
      customers
    }

    window.localStorage.setItem('loggedUser', JSON.stringify(persist))

    window.location.href = '/'
    // console.log(res)
    // console.log(res.data)
  } catch (error) {
    alert('Correo o contraseña incorrecta')
  }
}

const logoutUser = async () => {
  try {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('loggedUser')
    localStorage.removeItem('friends')
    await Swal.fire({
      title: 'Tu sesión ha caducado',
      icon: 'success',
      confirmButtonText: 'OK'
    })
    window.location.href = '/login'
  } catch {
    alert('Ha ocurrido un error al cerrar sesión')
  }
}

const validateCustomerToken = async () => {
  try {
    const configwithauthorization = await getConfigWithAuthorization()

    await axios.get(`${dbapiEndpoint}/api/v1/auth/customer/check`, configwithauthorization)

    return { success: true }
  } catch (error) {
    await Swal.fire({
      title: '',
      text: 'Necesitas acceso por parte del personal del establecimiento para usar el servicio de mesa.',
      icon: 'warning',
      confirmButtonText: 'OK',
      confirmButtonColor: '#202020'
    })

    throw error
  }
}

export {
  sendRegister,
  sendLogin,
  logoutUser,
  validateCustomerToken
}
