import React from 'react'

const CartIcon = ({ cartSize }) => {
  return (
    <div className='flex justify-center items-center'>
      <div className='relative py-2'>
        <div className='t-0 absolute left-3 bottom-1'>
          <p className='flex h-2 w-2 items-center justify-center rounded-full bg-blue-500 p-3 text-xs text-white'>
            {cartSize}
          </p>
        </div>
        <span className='material-symbols-outlined mt-3 h-6 w-6'>restaurant_menu</span>
      </div>
    </div>
  )
}

export default CartIcon
