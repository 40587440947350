import React from 'react'

// determinar un boton extra dentro para el modo Order (pending -> ready)

export default function SimpleCard ({ product, tableNumber, handleAction }) {
  return (

    <section className='border border-gray-300 bg-white rounded-lg shadow-lg mt-2 w-full max-w-[480px] hover:shadow-2xl transition pt-2 lg:pt-24'>
      <div className='p-2 flex flex-col justify-center items-center '>
        <div className='flex justify-between w-full items-baseline'>
          <div className='ml-4 mr-auto text-left flex flex-col'>
            <h4 className='font-medium text-l'>{product.name}</h4>
          </div>

        </div>
      </div>

      <div className='py-2 pr-6 flex justify-between w-full items-baseline'>
        <div className='ml-8 mr-auto text-left flex flex-col'>
          {product.price ? <h4 className='text-md'> {(product.price * product.quantity).toFixed(2)} €</h4> : null}
          {product.customer_comment
            ? (
              <div className='bg-gray-50 text-gray-700 text-left py-2 px-4 rounded-md border-gray-200 border text-sm flex flex-col gap-4'>
                <p className='inline-block'>
                  <span>{product.customer_comment}</span>
                </p>
              </div>
              )
            : null}
        </div>
        <span>x{product.quantity}</span>
      </div>
    </section>
  )
}
