import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '@mui/material/Modal'

const navigation = [
  {
    name: 'Facturas archivadas',
    href: '/dashboard/invoices',
    current: false
  },
  {
    name: 'Contraseña',
    href: '/account/configuration/coming-soon',
    current: false
  },
  {
    name: 'Correo electrónico y SMS',
    href: '/account/configuration/coming-soon',
    current: false
  },
  {
    name: 'Mejorar plan',
    href: '/account/configuration/coming-soon',
    current: false
  }
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24
}

const MyConfiguration = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <section className=''>
        <div className='w-full md:w-7/12 px-4 mx-auto'>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-6'>

            {navigation.map((item) => (
              <Link to={item.href} key={item.name}>
                <div
                  className='w-full flex items-center justify-between p-3 hover:bg-gray-100'
                  key={item.name}
                >
                  <div className='flex items-center px-1 py-1'>
                    <span className='text-slate-600  tracking-tight text-s'>
                      {item.name}
                    </span>
                  </div>
                  <div>
                    <span className='mr-2 text font-medium text-slate-600'>
                      {'>'}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <div className='text-center'>
            <button
              onClick={handleOpen}
            >
              <svg className='h-8 w-8 text-black' width='24' height='24' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>  <path stroke='none' d='M0 0h24v24H0z' />  <circle cx='12' cy='12' r='9' />  <line x1='12' y1='17' x2='12' y2='17.01' />  <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' /></svg>
            </button>
            <Modal
              open={open}
              onClose={handleClose}
            >
              <div
                style={style}
                className='relative bg-white rounded-lg shadow dark:bg-gray-700'
                tabIndex=''
              >
                <div className='flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600'>
                  <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
                    Información
                  </h3>
                </div>
                <div className='p-6 space-y-6'>
                  <p className='text-base leading-relaxed text-gray-500 dark:text-gray-400'>
                    Si no encuentras la opción para la tarea que
                    quieres realizar puedes enviarnos un correo a <b>contact@plisapp.com</b>
                    &nbsp;y te ayudaremos.
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </>
  )
}

export default MyConfiguration
