import React from 'react'

const ComingSoon = () => {
  return (
    <>
      <section className=''>
        <div className='w-full md:w-7/12 px-4 mx-auto'>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-6'>

            <div className='text-center m-5 space-y-3'>
              <img src='https://waymeet.es/wp-content/uploads/2023/05/clock.webp' alt='clock' className='w-12 mx-auto' />
              <h3 className='font-semibold'>¡Proximamente!</h3>
              <p>Estamos desarrollando esta funcionalidad, pero puedes ponerte en contacto con nosotros para cualquier consulta que necesites.</p>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default ComingSoon
