import glutenIco from '../resources/images/allegerns/gluten.ico'
import apioIco from '../resources/images/allegerns/apio.ico'
import crustaceansIco from '../resources/images/allegerns/crustaceans.ico'
import eggsIco from '../resources/images/allegerns/eggs.ico'
import fishIco from '../resources/images/allegerns/fish.ico'
import lupinsIco from '../resources/images/allegerns/lupins.ico'
import milkIco from '../resources/images/allegerns/milk.ico'
import molluscsIco from '../resources/images/allegerns/molluscs.ico'
import mustardIco from '../resources/images/allegerns/mustard.ico'
import peanutsIco from '../resources/images/allegerns/peanuts.ico'
import sesameIco from '../resources/images/allegerns/sesame.ico'
import soyaIco from '../resources/images/allegerns/soya.ico'
import sulphitesIco from '../resources/images/allegerns/sulphites.ico'
import treeNutsIco from '../resources/images/allegerns/tree-nuts.ico'

export function handleAddToCart (product, cart, setCart) {
  const existingProduct = cart.find((item) => item._id === product._id)
  if (existingProduct) {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === product._id ? { ...item, quantity: item.quantity + 1 } : item
      )
    )
  } else {
    setCart((prevCart) => [...prevCart, { ...product, quantity: 1 }])
  }
}

export function handleRemoveFromCart (product, cart, setCart) {
  const existingProduct = cart.find((item) => item._id === product._id)
  if (existingProduct) {
    if (existingProduct.quantity > 1) {
      setCart((prevCart) =>
        prevCart.map((item) =>
          item._id === product._id ? { ...item, quantity: item.quantity - 1 } : item
        )
      )
    } else {
      setCart((prevCart) => prevCart.filter((item) => item._id !== product._id))
    }
  }
}

export function getCartQuantity (cart) {
  return cart.reduce((total, product) => total + product.quantity, 0)
}

// export function renderAllergenIcons (allergens) {
//   return allergens.map((allergen, index) => {
//     let icon

//     switch (allergen) {
//       case 'apio':
//         icon = apioIco
//         break
//       case 'crustaceans':
//         icon = crustaceansIco
//         break
//       case 'eggs':
//         icon = eggsIco
//         break
//       case 'fish':
//         icon = fishIco
//         break
//       case 'gluten':
//         icon = glutenIco
//         break
//       case 'lupins':
//         icon = lupinsIco
//         break
//       case 'milk':
//         icon = milkIco
//         break
//       case 'molluscs':
//         icon = molluscsIco
//         break
//       case 'mustard':
//         icon = mustardIco
//         break
//       case 'peanuts':
//         icon = peanutsIco
//         break
//       case 'sesame':
//         icon = sesameIco
//         break
//       case 'soya':
//         icon = soyaIco
//         break
//       case 'sulphites':
//         icon = sulphitesIco
//         break
//       case 'tree-nuts':
//         icon = treeNutsIco
//         break
//       default:
//         return null
//     }

//     return <img src={icon} className='w-6' alt='' key={index} />
//   })
// };

export function renderAllergenIcons (allergens, showNames) {
  if (!allergens || allergens.length === 0) {
    return null // Si no hay alérgenos, no mostrar nada
  }

  const allergenIcons = {
    apio: { icon: apioIco, title: 'Apio' },
    crustaceans: { icon: crustaceansIco, title: 'Crustáceos' },
    eggs: { icon: eggsIco, title: 'Huevos' },
    fish: { icon: fishIco, title: 'Pescado' },
    gluten: { icon: glutenIco, title: 'Gluten' },
    lupins: { icon: lupinsIco, title: 'Altramuces' },
    milk: { icon: milkIco, title: 'Leche' },
    molluscs: { icon: molluscsIco, title: 'Moluscos' },
    mustard: { icon: mustardIco, title: 'Mostaza' },
    peanuts: { icon: peanutsIco, title: 'Cacahuetes' },
    sesame: { icon: sesameIco, title: 'Sésamo' },
    soya: { icon: soyaIco, title: 'Soja' },
    sulphites: { icon: sulphitesIco, title: 'Sulfitos' },
    'tree-nuts': { icon: treeNutsIco, title: 'Frutos secos' }
  }

  const validAllergens = Object.keys(allergenIcons)

  const allergensToDisplay =
    allergens.includes('all') ? validAllergens : allergens.filter((allergen) => validAllergens.includes(allergen))

  return allergensToDisplay.map((allergen, index) => (
    <div key={index} className='flex items-center'>
      <img src={allergenIcons[allergen].icon} className='w-6' alt={allergenIcons[allergen].title} />
      {showNames && <div className='ml-1 text-gray-700 text-sm'>{allergenIcons[allergen].title}</div>}
    </div>
  ))
}
