import React from 'react'

const PageNotFound = () => {
  navigator.serviceWorker.register('sw.js')

  function showNotification () {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('Vibration Sample', {
            body: 'Buzz! Buzz!',
            icon: '../images/touch/chrome-touch-icon-192x192.png',
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: 'vibration-sample'
          })
        })
      }
    })
  }

  return (
    <>
      <div className='lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16'>
        <div className='xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0'>
          <div className='relative'>
            <div className='absolute'>
              <div className=''>
                <h1 className='my-2 text-gray-800 font-bold text-2xl'>
                  Página no encontrada
                </h1>
                <p className='my-2 text-gray-800'>Parece que la página que estás buscando ha sido borrada, su nombre ha cambiado o está temporalmente no disponible.</p>
                {/* <Link to='/'>
                  <button className='sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-slate-900 text-white hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50'>
                    Ir a la página de inicio
                  </button>
                </Link> */}
              </div>
            </div>
            <div>
              <img src='https://i.ibb.co/G9DC8S0/404-2.png' alt='' />
            </div>
          </div>
        </div>
        <div>
          <img src='https://i.ibb.co/ck1SGFJ/Group.png' alt='' />
        </div>
      </div>

    </>
  )
}

export default PageNotFound
