import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'
import GoBackButton from '../../smallComponents/GoBackButton/GoBackButton'
import ArchiveButton from '../../smallComponents/Buttons/ArchiveButton'
import InvoiceElement from '../../smallComponents/InvoiceElement/InvoiceElement'

const businessService = require('../../services/businessService')
const customerService = require('../../services/customerService')

const ShowInvoice = () => {
  const navigate = useNavigate()
  const tableNumber = useParams().id

  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      const data = await businessService.getTotalFromTable(tableNumber)
      setInvoice(data)
      setLoading(false)
    }
    fetchData()
  }, [tableNumber])

  if (loading === true) {
    return <LoadingSpinner />
  }

  const handleArchiveButtonClick = async (number) => {
    await customerService.logoutCustomerFromTableNumber(number, navigate)
  }

  return (
    <>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className='w-full md:w-7/12 px-4 mx-auto'>
          <div className='flex w-full px-6 justify-between items-center mb-5 bg-white shadow-lg rounded-lg mt-6'>

            <GoBackButton />

            <h1 className='font-medium leading-tight text-xl mt-5 mb-5 text-gray-900'>
              Mesa {tableNumber}
            </h1>

            <ArchiveButton
              props={{ title: 'Archivar' }}
              onClick={() => handleArchiveButtonClick(tableNumber)}
            />
          </div>
        </div>

        {/* Invoice Block */}
        <div className='bg-white border rounded-lg shadow-lg px-6 py-8 max-w-lg mx-auto mt-8'>
          {/* <h1 className='font-bold text-xl my-4 text-center text-slate-800'> Restaurante inventado</h1> */}
          {/* <hr className='mb-2' /> */}
          <div className='flex justify-between mb-6'>
            <h1 className='font-bold'>FACTURA</h1>
            <div className='text-gray-700 text-sm'>
              <div>Fecha:</div>
              <div>{invoice.date}</div>
              {/* <div>Factura #: INV12345</div> */}
            </div>
          </div>
          <div className='mb-8 text-sm'>
            <h2 className='font-bold mb'>Factura a:</h2>
            <div className='text-gray-700 mb-2'>Mesa {tableNumber}</div>
          </div>
          <hr className='mb-2' />

          <table className='w-full mb-8 text-sm'>
            <thead>
              <tr>
                <th className='text-left font-bold text-gray-700'>U</th>
                <th className='text-left font-bold text-gray-700'>Descripción</th>
                <th className='text-right font-bold text-gray-700'>PU</th>
                <th className='text-right font-bold text-gray-700'>IMP</th>
              </tr>
            </thead>
            <tbody>
              <>
                {invoice.products.map((product, index) => (
                  <InvoiceElement key={index} product={product} />
                ))}
              </>
              <tr>
                <td className='text-left text-gray-700'> </td>
                <td className='text-left text-gray-700'> </td>
                <td className='text-right text-gray-700'> </td>
                <td className='text-right text-gray-700'> </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className='text-left font-bold text-gray-700 text-xl'>TOTAL</td>
                <td className='text-left font-bold text-gray-700' />
                <td className='text-right font-bold text-gray-700' />
                <td className='text-right font-bold text-gray-700 text-xl'>{invoice.total_price.toFixed(2)}€</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </motion.div>
    </>
  )
}

export default ShowInvoice
