const capitalizeName = (name) => {
  let capitalizedName = ''
  const nameArray = name.split(' ')

  for (let i = 0; i < nameArray.length; i++) {
    const word = nameArray[i]
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedName += capitalizedWord + ' '
  }

  return capitalizedName.trim()
}

export { capitalizeName }
