import React from 'react'
import { useNavigate } from 'react-router-dom'
import CartIcon from '../../smallComponents/CartIcon/CartIcon'
import { validateCustomerToken } from '../../services/authService'

export default function Footer ({ cartSize, order, businessUsername, customer, tableNumber }) {
  const navigate = useNavigate()

  const onSubmit = async (productDetails) => {
    try {
      if (customer === true) {
        await validateCustomerToken()
      }
      navigate(`/${businessUsername}/pedido`, { state: { productDetails, customer, tableNumber } })
    } catch (error) {
    }
  }

  return (
    <footer className='fixed bottom-0 left-0 right-0 border border-gray-100 bg-white shadow-lg w-full max-w-[480px]'>
      <div className='flex justify-end space-x-5'>

        <CartIcon cartSize={cartSize} />
        <button onClick={() => onSubmit(order)} className='bg-black text-white py-2 px-4 rounded'>Revisar pedido</button>
      </div>
    </footer>
  )
}
