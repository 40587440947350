import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { IconButton } from '@mui/material'
// import { ShowHidePassword } from '../../components/ShowHidePassword'

const authService = require('../../services/authService')

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm()

  // Show/Hide password --------
  const [values, setValues] = useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }
  // --------

  return (
    <>
      <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full max-w-md space-y-8'>
          <div>
            <img
              className='mx-auto h-10 w-auto'
              src='https://waymeet.es/wp-content/uploads/2022/08/cropped-logowtitulo-1.png'
              alt='Waymeet Logo'
            />
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              Registrate
            </h2>
            <p className='mt-2 text-center text-sm text-gray-600'>
              es rápido y fácil
            </p>
          </div>
          <form
            className='mt-8 space-y-6'
            method='POST'
            onSubmit={handleSubmit(authService.sendRegister)}
          >
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='rounded-md space-y-2'>
              <div>
                <label htmlFor='email' className='sr-only'>
                  Email address
                </label>
                <input
                  name='email'
                  type='text'
                  className='relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='Correo electrónico'
                  {...register('email_address', {
                    required: true
                    // pattern: /^[A-Z0-9._%+-]+@estudiant\.uib\.cat$/i
                  })}
                />
                {errors.email_address && (
                  <span className='text-red-500 text-sm' role='alert'>
                    Escribe una dirección de correo válida (@estudiant.uib.cat).
                  </span>
                )}
              </div>
              <div>
                <label htmlFor='name' className='sr-only'>
                  Full Name
                </label>
                <input
                  name='name'
                  type='text'
                  className='capitalize relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='Nombre completo'
                  {...register('name', {
                    required: true,
                    pattern: /^[a-zA-Z]+\s+[a-zA-Z]/gm
                  })}
                />
                {errors.full_name && (
                  <span className='text-red-500 text-sm' role='alert'>
                    Este campo es obligatorio.
                  </span>
                )}
              </div>

              <div className='flex'>
                <label htmlFor='password' className='sr-only'>
                  Password
                </label>
                <input
                  type={values.showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange('password')}
                  className='relative block w-full pl-4 appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='Contraseña'
                  {...register('password', {
                    required: true,
                    pattern: /^[a-zA-Z0-9@ñ#%$€&|~<>·\-+*=_:.?!]*$/gm,
                    minLength: 7
                  })}
                />

                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword
                    ? <svg className='h-5 w-5 text-white' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>  <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />  <circle cx='12' cy='12' r='3' /></svg>
                    : <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'> <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21' /> </svg>}
                </IconButton>

              </div>
              {errors.password && (
                <span className='text-red-500 text-sm' role='alert'>
                  Tu contraseña debe tener más de 7 letras, números o signos de puntuación habituales.
                </span>
              )}

              <div className='pt-3'>
                <label htmlFor='birth_date' className='text-gray-800 text-sm'>
                  Fecha de nacimiento
                </label>
                <input
                  name='birth_date'
                  type='text'
                  min='1950-12-31' max='2003-12-31'
                  className='relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder='DD/MM/AAAA'
                  {...register('birth_date', {
                    required: true,
                    pattern:
                        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
                  })}
                />
                {errors.birth_date && (
                  <span className='text-red-500 text-sm' role='alert'>
                    Formato incorrecto o no cumples con los requisitos de edad.
                  </span>
                )}
              </div>
              <div>
                <label htmlFor='genre' className='text-gray-800 text-sm'>
                  Sexo
                </label>
                <div className='flex'>
                  <div className='form-check form-check-inline'>
                    <input
                      className='sm:text-sm form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                      type='radio'
                      name='genre'
                      value='female'
                      {...register('genre', {
                        required: true
                      })}
                    />
                    <label
                      className='sm:text-sm form-check-label inline-block text-gray-700'
                      htmlFor='female'
                    >
                      Mujer
                    </label>
                  </div>
                  <div className='form-check form-check-inline ml-3'>
                    <input
                      className='sm:text-sm form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                      type='radio'
                      name='genre'
                      value='male'
                      {...register('genre', {
                        required: true
                      })}
                    />
                    <label
                      className='sm:text-sm form-check-label inline-block text-gray-800'
                      htmlFor='male'
                    >
                      Hombre
                    </label>
                  </div>
                  <div className='form-check form-check-inline ml-3'>
                    <input
                      className='sm:text-sm form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                      type='radio'
                      name='genre'
                      value='unknown'
                      {...register('genre', {
                        required: true
                      })}
                    />
                    <label
                      className='sm:text-sm form-check-label inline-block text-gray-800'
                      htmlFor='unknown'
                    >
                      Prefiero no decirlo
                    </label>
                  </div>
                </div>
                {errors.genre && (
                  <span className='text-red-500 text-sm' role='alert'>
                    Este campo es obligatorio.
                  </span>
                )}
              </div>
            </div>

            <div className='text-center items-center justify-between'>
              <div className='items-center text-sm'>
                ¿Ya tienes cuenta?&nbsp;
                <a
                  href='/login'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Inicia sesión
                </a>
              </div>
            </div>

            <div className='text-center text-xs text-gray-500 mt-1'>
              Al hacer clic en Registrarse, aceptas nuestras&nbsp;
              <a
                className='no-underline border-b border-grey-dark text-grey-dark'
                href='https://waymeet.es/legal-notice'
              >
                Condiciones
              </a>{' '}
              . Para saber más, visita nuestra&nbsp;
              <a
                className='no-underline border-b border-grey-dark text-grey-dark'
                href='https://waymeet.es/privacy-policy'
              >
                Política de privacidad
              </a>{' '}
              , así como el uso que hacemos de las cookies y tecnologías
              similares en nuestra&nbsp;
              <a
                className='no-underline border-b border-grey-dark text-grey-dark'
                href='https://waymeet.es/cookies-policy'
              >
                Política de cookies.
              </a>
            </div>

            <div>
              <button
                type='submit'
                  // onClick={this.handleFormSubmit}
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              >
                <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z' />
                  </svg>
                </span>
                Registrarse
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Register
