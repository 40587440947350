import React from 'react'

const WhiteDiv = (props) => {
  return (
    <>
      <section className='max-w-6xl p-2 mx-auto bg-white rounded-lg sm:shadow-md sm:bg-white sm:p-8 md:bg-white space-y-5'>
        <div className=''>
          {props.coverImage
            ? <div className='flex flex-col justify-center items-center pb-5'> <img src={props.coverImage} alt='logo' className='h-25 w-25' /> </div>
            : null}
          <h1 className='font-semibold text-2xl mb-4'>
            {props.title}
          </h1>
        </div>
        {props.children}
      </section>
    </>
  )
}

export default WhiteDiv
