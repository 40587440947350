import { Dropdown } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../smallComponents/LoadingSpinner/LoadingSpinner'
import SimpleCardProduct from '../components/SimpleCard/SimpleCardProduct'
import Footer from '../components/Footer/Footer'
import { getCartQuantity } from '../utils/MenuFunctions'
import wand from '../resources/images/wand.png'

const businessService = require('../services/businessService')
const customerService = require('../services/customerService')

export default function BusinessMenu () {
  const navigate = useNavigate()
  const username = useParams().business_username

  const [cart, setCart] = useState([])
  const [loading, setLoading] = useState(true)
  const [businessInfo, setBusinessInfo] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  function loadReviews (businessInfo) {
    const LOCAL_STORAGE_KEY = 'googleReviewShowed'
    const NOTIFICATION_MESSAGE = `¡Nos gustaría saber tu opinión sobre ${businessInfo.name}!`
    const reviewUrl = businessInfo.review_url
    let googleReviewShowed = sessionStorage.getItem(LOCAL_STORAGE_KEY) === 'true'

    if (reviewUrl && !googleReviewShowed) {
      if (Notification.permission === 'granted') {
        const notification = new Notification(NOTIFICATION_MESSAGE)
        notification.close()
      }
      customerService.askForReview(reviewUrl)
      googleReviewShowed = true
    }

    sessionStorage.setItem(LOCAL_STORAGE_KEY, googleReviewShowed)
  }

  useEffect(() => {
    // Get Customer session
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const LAST_VISIT_KEY = 'lastVisit'
    window.localStorage.setItem('accessToken', 'Bearer ' + urlParams.get('t'))

    async function fetchData () {
      setLoading(true)

      try {
        const data = await businessService.getOneBusinessPublicInfo(username)
        setBusinessInfo(data)

        if (!data._id) navigate('/not-found')

        // Obtener las categorías únicas de los productos
        const uniqueCategories = [...new Set(data.products.map(product => product.category))]
        setCategories(uniqueCategories)

        setInterval(() => {
          loadReviews(data)
        }, 1000 * 60 * 20)

        if (localStorage.getItem(LAST_VISIT_KEY)) {
          const lastVisit = new Date(localStorage.getItem(LAST_VISIT_KEY))
          const minutesSinceLastAccess = (new Date() - lastVisit) / 1000 / 60
          if (minutesSinceLastAccess >= 20) {
            loadReviews(data)
            localStorage.removeItem(LAST_VISIT_KEY)
          }
        } else {
          localStorage.setItem(LAST_VISIT_KEY, new Date())
        }
      } catch (error) {}

      setLoading(false)

      if (Notification.permission !== 'granted' && Notification.permission !== 'denied') Notification.requestPermission()
    }
    fetchData()
  }, [username, navigate])

  const handleAddToCart = (product) => {
    const existingProduct = cart.find((item) => item._id === product._id)
    if (existingProduct) {
      setCart((prevCart) =>
        prevCart.map((item) =>
          item._id === product._id ? { ...item, quantity: item.quantity + 1 } : item
        )
      )
    } else {
      setCart((prevCart) => [...prevCart, { ...product, quantity: 1 }])
    }
  }

  const handleRemoveFromCart = (product) => {
    const existingProduct = cart.find((item) => item._id === product._id)
    if (existingProduct) {
      if (existingProduct.quantity > 1) {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item._id === product._id ? { ...item, quantity: item.quantity - 1 } : item
          )
        )
      } else {
        setCart((prevCart) => prevCart.filter((item) => item._id !== product._id))
      }
    }
  }

  if (loading === true) {
    return <LoadingSpinner />
  }

  const customerRequests = ['waiter', 'pay', 'pay-card']

  return (
    <>

      <div className='mb-16 max-w-[480px]'>

        {businessInfo.table_ordering === true && (
          <div className='mt-3 ml-2'>
            <Dropdown
              label={<img src={wand} alt='' className='w-5' />}
              inline
            >
              {customerRequests
                .map((request, index) => (
                  <Dropdown.Item key={index}>
                    <button className='mx-4 py-9 text-gray-800 dark:text-gray-300' onClick={() => customerService.tableCall(request)}>
                      {request === 'pay-card' && 'Pedir la cuenta (tarjeta)'}
                      {request === 'pay' && 'Pedir la cuenta (efectivo)'}
                      {request === 'waiter' && 'Llamar al camarero'}
                    </button>
                  </Dropdown.Item>
                ))}
              <Dropdown.Item>
                <button className='mx-4 py-9 text-gray-800 dark:text-gray-300' onClick={() => customerService.allergensInfo()}>
                  Información de alergenos
                </button>
              </Dropdown.Item>
            </Dropdown>

          </div>
        )}

        {businessInfo.table_ordering === false && (
          <button
            onClick={() => customerService.allergensInfo()}
            className='ml-2 py-2'
          >
            <svg class='h-5 w-5 text-black' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
            </svg>
          </button>
        )}

        <div className='flex justify-center items-center pb-5 bg-white'> <img src={businessInfo.profile_photo} alt='logo' className='w-15' /> </div>

        <div className='menu-container overflow-x-auto border-none w-full shadow-md bg-white'>
          <div className='menu-wrapper inline-flex whitespace-nowrap'>
            <ul className='menu-list list-none m-0 p-0'>
              {categories.map((category, index) => (
                <li
                  key={index}
                  className={`menu-item inline-block py-2 px-4 ${selectedCategory === category ? 'text-gray-800 border-b-blue-400 border-b-4' : 'text-gray-600'}`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {businessInfo.products.map((product, index) => {
          if (selectedCategory && product.category !== selectedCategory) {
            return null
          }

          return (
            <div key={index}>
              <SimpleCardProduct tableOrdering={businessInfo.table_ordering} product={product} addToCart={handleAddToCart} removeFromCart={handleRemoveFromCart} cart={cart} />
            </div>
          )
        })}

        <p className='text-gray-500 m-8 text-sm'>Si necesitas atención personalizada o tienes alergias alimentarias, habla con alguno de los camareros</p>

      </div>

      {businessInfo.table_ordering === true && (
        <Footer cartSize={getCartQuantity(cart)} order={cart} businessUsername={username} customer />
      )}

    </>
  )
}
