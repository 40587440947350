import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { motion } from 'framer-motion'
import LoadingSpinner from '../../smallComponents/LoadingSpinner/LoadingSpinner'
import GoBackButton from '../../smallComponents/GoBackButton/GoBackButton'

const customerService = require('../../services/customerService')

const ShowQR = () => {
  const tableNumber = useParams().id

  const [CustomerUrlAccess, setCustomerUrlAccess] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      const data = await customerService.getCustomerUrlAccessFromTableNumber(tableNumber)
      setCustomerUrlAccess(data)
      setLoading(false)
    }
    fetchData()
  }, [tableNumber])

  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className='w-full md:w-7/12 px-4 mx-auto'>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-6'>

            <div className='px-6 space-y-4 mt-2 mb-14'>
              <GoBackButton />
              <div className='flex justify-center text-center'>
                <h1 className='font-medium leading-tight text-2xl mt-5 mb-5 text-gray-900'>
                  Mesa {tableNumber}
                </h1>
              </div>
              <div className='flex justify-center text-center'>
                <img src={CustomerUrlAccess.qr} alt='' />
              </div>

            </div>

          </div>
        </div>
      </motion.div>
    </>
  )
}

export default ShowQR
