import { useState, useEffect } from 'react'

const dateAndHour = () => {
  // Obtener la fecha y hora actual
  const fechaActual = new Date()

  // Obtener los componentes de la fecha y hora
  const dia = fechaActual.getDate().toString().padStart(2, '0')
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0') // Los meses comienzan desde 0, por lo que se suma 1
  const anio = fechaActual.getFullYear()
  const hora = fechaActual.getHours().toString().padStart(2, '0')
  const minutos = fechaActual.getMinutes().toString().padStart(2, '0')

  // Formatear los componentes en el formato "dd/mm/aaaa hh:mm"
  const fechaFormateada = dia + '/' + mes + '/' + anio + ' ' + hora + ':' + minutos

  return fechaFormateada
}

const clientTimezoneOffset = () => {
  const clientTimezoneOffsetMinutes = new Date().getTimezoneOffset()
  return clientTimezoneOffsetMinutes * 60000
}

export function useNumberIncrementer (initialValue = 0) {
  const [number, setNumber] = useState(initialValue)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNumber(prevNumber => prevNumber + 1)
    }, 60000) // Incrementa cada minuto

    return () => clearInterval(intervalId)
  }, [initialValue])

  return number
}

export function useMinutesManager (initialValues = {}) {
  const [minutes, setMinutes] = useState(initialValues)

  useEffect(() => {
    // Lógica para actualizar los minutos cada minuto
    const intervalId = setInterval(() => {
      // Actualiza los minutos aquí
      // Por ejemplo:
      const updatedMinutes = { ...minutes }
      Object.keys(updatedMinutes).forEach((key) => {
        updatedMinutes[key] += 1
      })
      setMinutes(updatedMinutes)
    }, 60000) // 60000 ms = 1 minuto

    return () => clearInterval(intervalId)
  }, [])

  // Función para actualizar un valor específico de minutos
  const updateMinute = (key, value) => {
    setMinutes((prevMinutes) => {
      if (prevMinutes[key] === value) {
        // No realizar una actualización si el valor es el mismo
        return prevMinutes
      }
      return { ...prevMinutes, [key]: value }
    })
  }

  return { minutes, updateMinute }
}

export {
  dateAndHour,
  clientTimezoneOffset
}
