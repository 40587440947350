import React from 'react'

const ArchiveButton = ({ props, onClick }) => (
  <>
    <button className='inline-flex items-center px-4 py-2 bg-yellow-300 hover:bg-yellow-400 text-white text-sm font-medium rounded-md' onClick={onClick}>
      <svg className='h-5 w-5 text-white' width='24' height='24' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>  <path stroke='none' d='M0 0h24v24H0z' />  <path d='M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2' /></svg>
      <span className='text-white text-sm ml-2'>{props.title}</span>
    </button>
  </>
)

export default ArchiveButton
