import { Outlet } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import Nav from '../components/NavMenu'
import LoadingSpinner from '../smallComponents/LoadingSpinner/LoadingSpinner'

const Layout = () => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(!user)

  useEffect(() => {
    setLoading(!user)
  }, [user])

  if (loading) {
    <LoadingSpinner />
  }

  return (
    <>
      <Nav profilePhoto={user.profile_photo} />
      <Outlet />
    </>
  )
}

export default Layout
