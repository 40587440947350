// unused
import React from 'react'
import { useNavigate } from 'react-router-dom'

const GoBackButton = () => {
  const navigate = useNavigate()

  function handleClick () {
    navigate(-1)
  }

  return (
    <button onClick={handleClick} className='rounded bg-gray-700 hover:bg-gray-600 p-2'>
      <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6' />
      </svg>
    </button>
  )
}

export default GoBackButton
