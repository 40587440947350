import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { dbapiEndpoint, config } from '../utils/httpVars'

const sendRecoveryLink = (e) => {
  e.preventDefault()
  axios
    .post(
      `${dbapiEndpoint}/api/v1/mail/forgot-password`,
      {
        email: e.target[0].value
      },
      config
    )
    .then((res) => {
      console.log(res)
      console.log(res.data)
      Swal.fire({
        showConfirmButton: true,
        icon: 'success',
        text: 'Revisa tu correo, se te ha enviado un enlace para crear una nueva contraseña'
      })
    })
}

const ResetPassword = (e, token) => {
  // const navigate = useNavigate()
  axios
    .post(
      `${dbapiEndpoint}/api/v1/users/reset-password`,
      {
        password: e.password,
        token
      },
      config
    )
    .then((res) => {
      console.log(res)
      console.log(res.data)
      Swal.fire({
        showConfirmButton: true,
        icon: 'success',
        text: 'Tu contraseña se ha restablecido correctamente.'
      })
      // navigate("/", { replace: true });
    })
    .catch((error) => alert(error))
}

export {
  sendRecoveryLink,
  ResetPassword
}
