import React from 'react'
import './LoadingSpinner.css'

const LoadingSpinner = () => (
  <>
    <div className='flex items-center justify-center h-screen'>
      <div className='lds-ripple'><div /><div /></div>
    </div>
  </>
)

export default LoadingSpinner
