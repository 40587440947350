import React from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const OrderPageSwitcher = ({ tableNumber }) => {
  const navigate = useNavigate()

  const mostrarSweetAlert = () => {
    Swal.fire({
      html: '<br/> <p>¿Cómo se va a atender a esta mesa?</p> <br/>',
      showCancelButton: true,
      // target: document.getElementById('trial'),
      confirmButtonText: 'QR para clientes',
      cancelButtonText: 'Tomar nota',
      confirmButtonColor: 'rgb(51 65 85)',
      cancelButtonColor: '#ffbe0b',
      showLoaderOnConfirm: true,
      preConfirm: (choice) => {
        if (choice) {
          navigate(`/dashboard/table-management/qr/${tableNumber}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      customClass: {
        confirmButton: 'inline-flex items-center w-full justify-center px-4 py-2 text-white text-sm font-medium rounded-md mb-2',
        cancelButton: 'inline-flex items-center w-full justify-center px-4 py-2 text-white text-sm font-medium rounded-md'
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        navigate(`/dashboard/table-management/take-note/${tableNumber}`)
      }
    })
  }

  return (
    <>
      <button
        className='inline-flex items-center px-4 py-2 bg-slate-800 hover:bg-slate-700 text-white text-sm font-medium rounded-md'
        onClick={mostrarSweetAlert}
      >
        <svg className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z'
          />
        </svg>
        Pedir
      </button>
    </>
  )
}

export default OrderPageSwitcher
