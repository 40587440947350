import React from 'react'

const FilterButton = (props) => (
  <>
    <div className='inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-800 text-white text-sm font-medium rounded-md'>
      <span className='text-white text-sm'>{props.title}</span>
    </div>
  </>
)

export default FilterButton
