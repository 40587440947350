import axios from 'axios'
import axiosPrivate from '../middleware/axiosInterceptor'
import { dbapiEndpoint, configwithauthorization, getConfigWithAuthorization } from '../utils/httpVars'
import Swal from 'sweetalert2'
import { renderAllergenIcons } from '../utils/MenuFunctions'
import ReactDOMServer from 'react-dom/server'
import { validateCustomerToken } from './authService'
import googleReviews from '../resources/images/googlereviews.jpeg'

const getCustomersFromBusiness = async () => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/customer/tables`, configwithauthorization)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Debe iniciar sesión para obtener esta información')
    } else {
      console.error('Error en getCustomersFromBusiness.')
    }
  }
}

const getAllCustomers = async () => {
  try {
    const res = await axiosPrivate.get(`${dbapiEndpoint}/api/v1/customer`, configwithauthorization)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Debe iniciar sesión para obtener esta información')
    } else {
      console.error('Error en getCustomersFromBusiness.')
    }
  }
}

const logoutCustomerFromTableNumber = async (number, navigate) => {
  Swal.fire({
    title: 'Confirmación',
    html: `<p>Todos los pedidos de la mesa actual serán archivados.</p> <br/>
           <p>Y la mesa quedará libre para nuevos comensales.</p>
           `,
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#2ECC71',
    cancelButtonText: 'Cancelar'
  }).then(async (result) => {
    if (result.isConfirmed) {
      await axiosPrivate.post(`${dbapiEndpoint}/api/v1/auth/customer/logout`, { table: number }, configwithauthorization)
      navigate(-1)
    }
  })
}

const getCustomerUrlAccessFromTableNumber = async (number) => {
  try {
    const res = await axiosPrivate.post(`${dbapiEndpoint}/api/v1/auth/customer/generateToken`,
      { tableNumber: number },
      configwithauthorization)
    return res.data
  } catch (error) {
    console.log(`Error when getting data (getInvoiceFromTableNumber) from backend: ${error}`)
    throw error
  }
}

const createOrder = async (data, comment) => {
  try {
    const configwithauthorization = await getConfigWithAuthorization()

    const body = {
      products: data,
      comment
    }

    const response = await axios.post(`${dbapiEndpoint}/api/v1/customer/orders`,
      body,
      configwithauthorization
    )
    Swal.fire({
      title: '¡Pedido creado!',
      text: 'Tu pedido se esta procesando.',
      icon: 'success',
      confirmButtonText: 'Vale'
    })
    console.log(response)
  } catch (error) {
    console.log('Error creating order')
    alert('Parece que ha habido un error al crear tu pedido. Vuelve a intentarlo o contacta al personal del restaurante')
  }
}

const createOrderAsBusiness = async (data, tableNumber, comment) => {
  try {
    const configwithauthorization = await getConfigWithAuthorization()

    const body = {
      products: data,
      comment
    }

    const response = await axiosPrivate.post(`${dbapiEndpoint}/api/v1/business/orders/${tableNumber}`,
      body,
      configwithauthorization
    )
    Swal.fire({
      title: '¡Pedido creado!',
      text: 'Tu pedido se esta procesando.',
      icon: 'success',
      confirmButtonText: 'Vale'
    })
    console.log(response)
  } catch (error) {
    console.log('Error creating order')
    alert('Parece que ha habido un error al crear tu pedido. Vuelve a intentarlo o contacta al personal del restaurante')
  }
}

const tableCall = async (request) => {
  try {
    const validationSuccess = await validateCustomerToken()

    if (validationSuccess.success) {
      const configWithAuthorization = await getConfigWithAuthorization()

      try {
        await axios.post(`${dbapiEndpoint}/api/v1/customer/table-call/:request`,
          configWithAuthorization
        )

        Swal.fire({
          title: '¡Enviado!',
          text: 'En unos momentos te atenderá un camarero.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#2ECC71'
        })
      } catch (error) {
        alert('Parece que esta función no está disponible en este momento.')
      }
    }
  } catch (error) {

  }
}

const allergensInfo = async () => {
  try {
    const allergensHTML = ReactDOMServer.renderToString(renderAllergenIcons(['all'], true))

    Swal.fire({
      html: allergensHTML,
      icon: 'info',
      confirmButtonColor: '#2ECC71'
    })
  } catch (error) {
    alert('Parece que esta función no esta disponible en este momento')
  }
}

const askForReview = async (reviewUrl) => {
  try {
    Swal.fire({
      html: `<img src="${googleReviews}" class='w-4/6 mx-auto' /> Tu opinión es importante para nosotros. ¿Te gustaría dejar una reseña?`,
      showCancelButton: true,
      confirmButtonColor: '#2ECC71',
      confirmButtonText: 'Sí, dejar reseña',
      cancelButtonText: 'No, gracias'
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(reviewUrl, '_blank')
      }
    })
  } catch (error) {
    console.error('Error in askForReview function')
  }
}

export {
  getCustomersFromBusiness,
  getAllCustomers,
  logoutCustomerFromTableNumber,
  getCustomerUrlAccessFromTableNumber,
  createOrder,
  createOrderAsBusiness,
  tableCall,
  allergensInfo,
  askForReview
}
