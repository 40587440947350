import React from 'react'

const InvoiceButton = ({ onClick }) => (
  <>
    <button
      className='inline-flex items-center px-4 py-2 bg-green-400 hover:bg-green-500 text-white text-sm font-medium rounded-md'
      onClick={onClick}
    >
      <svg className='h-5 w-5 text-white' width='24' height='24' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round'>  <path stroke='none' d='M0 0h24v24H0z' />  <rect x='5' y='3' width='14' height='18' rx='2' />  <line x1='9' y1='7' x2='15' y2='7' />  <line x1='9' y1='11' x2='15' y2='11' />  <line x1='9' y1='15' x2='13' y2='15' /></svg>
      Ver factura
    </button>
  </>
)

export default InvoiceButton
